$primary-color: black;

$coolgames-blue: #4380ed;


$bg: white;
$debug-border: 1px solid #f00;

body {
  color: $primary-color;
  background-color: rgba($coolgames-blue, .05);
}

.main-content {
  margin-top: 7em;
  margin-bottom: 10em;
}

.footer {
  z-index: 999;
  background-color: #4380ed !important;

  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
}

.ui.disabled.input, .ui.input:not(.disabled) input[disabled] {
  opacity: 1;
}

#main-menu {
  background-color: #fd9111;
}

#main-menu.ui.inverted.menu .item.disabled,
#main-menu.ui.inverted.menu .item.disabled:hover {
  color: rgba(225, 225, 225, .5) !important;
}

.ui.segment, .ui.table {
  box-shadow: 0 1px 2px 0 rgba($coolgames-blue, .3);
}

.ui.table {
  border: 1px solid rgba($coolgames-blue, .5);
}

.ui.celled.table tr td {
  border: 1px solid rgba($coolgames-blue, .1);
}

.ui.table thead th {
  background-color: rgba($coolgames-blue, .1);
}

.ui.main-content .text.container {

}

.dashboard {

  .header {
    text-align: center;
  }

  .date-inputs {
    margin-bottom: 1em;
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;

    .segment {
      border: none !important;
    }


  }

  .date-input, .date-select {
    font-size: 16px;
    margin: 1em;
  }

  .date-input {
    text-align: center;
  }

  .date-select {
    //border: $debug-border !important;
    margin: 0;
  }

  .download-csv {

  }

  .statistics {
    text-align: center !important;

    .statistic {

      background-color: rgba($coolgames-blue, .1);
      border-top-left-radius: 1em;
      border-top-right-radius: 1em;
      padding: 1.5em;
      margin-bottom: 0;

      border-bottom: 1px solid rgba($coolgames-blue, .3);

      cursor: pointer;

      & > .value, & > .label {
        color: rgba($primary-color, .6);
      }

      &.active {
        background-color: #fff;
        border: 1px solid rgba($coolgames-blue, .5);
        border-bottom: none;

        cursor: default;

        & > .value, & > .label {
          color: rgba($primary-color, 1);
        }
      }
    }
  }

  .segment.chart {
    margin-top: 0;

    border: 1px solid rgba($coolgames-blue, .5);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;

    height: 500px;
  }
}

.icon.download {
  color: rgba($coolgames-blue, 1);
}

.ui.page.settings .ui.menu a:first-child {
  margin-left: 0;
}

.ui.page.settings .ui.menu a:last-child {
  margin-right: 0 !important;
}
